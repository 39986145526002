<template>
  <div>
    <Row :gutter="6" class="m-b-5 p-t-10">
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button size="small" type="success" @click="handleAdd">新增商品附加描述</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns"></Table>

    <AddOrEdit ref="addOrEditAdditional" @on-success="initBaseData"/>
  </div>
</template>

<script>
import AddOrEdit from './AddOrEdit'
import { getAdditional, deleteAdditional } from '@/api/product/cloudinventory'

export default {
  components: {
    AddOrEdit
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      list: [],
      tableColumns: [
        { title: '编号', key: 'id', width: 100 },
        { title: '标题', key: 'name', width: 200 },
        { title: '内容', key: 'content', tooltip: true },
        {
          title: '操作',
          align: 'right',
          render: (h, params) => {
            const buttons = [
              h('a', {
                on: {
                  click: () => {
                    this.handleEdit(params.row)
                  }
                }
              }, '编辑'),
              h('Divider', { attrs: { type: 'vertical' } }),
              h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleDelete(params.row)
                  }
                }
              }, '移除')
            ]

            return h('div', buttons)
          }
        }
      ]
    }
  },
  mounted () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      getAdditional({ publisherId: this.publisherId }).then(res => {
        this.list = res
      })
    },
    handleAdd () {
      this.$refs.addOrEditAdditional.showModal()
    },
    handleEdit (params) {
      this.$refs.addOrEditAdditional.showModal(params)
    },
    handleDelete (params) {
      this.$Modal.confirm({
        title: '操作确认？',
        content: '<p>您确认要移除标题为：【 ' + params.name + '】的商品描述吗？</p>',
        loading: true,
        onOk: () => {
          deleteAdditional({ id: params.id }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '移除商品描述成功！', title: '操作成功' })
              this.initBaseData()
            }
            this.$Modal.remove()
          })
        }
      })
    }
  }
}
</script>
